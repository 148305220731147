import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import 'unfetch/polyfill';
import accounting from 'accounting';
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null
    };


  }



  setValue = (event) => {
    console.log(event.target.value)
    this.setState({value: event.target.value.toUpperCase()});
  }

  getRDWData = (event) => {
    console.log('Click');
    fetch(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${this.state.value}`)
      .then(r => r.json())
      .then(data => this.setState(data[0]))
  };

  render() {
    return (
      <div>
        <input  value={this.state.value} onChange={this.setValue} />
        <button onClick={this.getRDWData}>Lookup</button>

        {!! this.state.catalogusprijs && 
        <h2>
        {accounting.formatMoney(this.state.catalogusprijs, "€ ", 2, ".", ",")}</h2>}
      </div>
    );
  }
}

export default App;
